<template>
  <div class="bg-white">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <div style="cursor: pointer;" @click="prevPage">
            <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-center align-items-center">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            {{ editId ? 'Edit' : 'Tambah' }} Produk Eceran
          </h1>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button class="px-3" :disabled="disabled || disabledWarehouse"
            @click="editId ? updateItem() : createItem()">
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row class="mx-0 mt-5 justify-content-center">
        <b-col cols="12" md="8">
          <ProdukEceran :form-data="formPayload" :messages="messages" :image-url="image_url" />
          <!-- Barcode -->
          <div class="form-group">
            <label for="">Barcode Number:</label>
            <div class="input-group">
              <input v-model="barcode_number" type="text" class="form-control"
                :class="messages.hasOwnProperty('barcode_number') && messages.barcode_number.length > 0 ? 'is-invalid' : ''"
                placeholder="Barcode Number...">
              <div class="input-group-append">
                <button class="btn btn-success" @click="toggleBarcodeScanner()">
                  Scan
                </button>
                <button class="btn btn-primary" @click="generateBarcode()">
                  Generate
                </button>
              </div>
            </div>
            <small v-if="messages.hasOwnProperty('barcode_number') && messages.barcode_number.length > 0"
              class="text-danger">
              {{ messages.barcode_number.join(', ') }}
            </small>
          </div>

          <div class="mb-2">
            <StreamBarcodeReader v-if="showBarcodeScanner" @decode="onDecode" @loaded="onLoaded" />

            <vue-barcode v-if="barcode_number != null && barcode_number != ''" :value="barcode_number"
              element-tag="img">
              Render failed
            </vue-barcode>
          </div>
          <!-- End Barcode -->
          <PriceDiscount :is_parent="false" :form-data="formPayload" :messages="messages" />
          <InitialStock :is-eceran="true" :form-data="formPayload" :messages="messages" :arr-checked="arr_warehouse"
            @initialStocks="getPayloadInitialStock" />
          <!-- <Duplikat
            :arr-checked="formPayload.warehouses_uuid"
            :messages="messages"
            @getWarehouseUuid="getWarehouseUuid"
          /> -->
          <Riwayat :uuid="$route.params.id" v-if="$route.params.id" />
        </b-col>
      </b-row>
    </b-container>
    <!-- <ModalSave
      :typeModal="'eceran'"
      :submit-data="createItem"
      :update-item="updateItem"
      :edit-id="editId"
      :is-loading="isLoading"
    /> -->
  </div>
</template>

<script>
import {
  BButton, BModal, BRow, BCol, BContainer, BProgress,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { mapActions } from 'vuex'

import ProdukEceran from '@/components/MasterProduct/Part/ProdukEceran'
import PriceDiscount from '@/components/MasterProduct/Part/PriceDiscount'
import InitialStock from '@/components/MasterProduct/Part/InitialStocks'
import Duplikat from '@/components/MasterProduct/Part/Duplikat'
import Riwayat from '@/components/MasterProduct/Part/Riwayat'
import ModalSave from '@/components/MasterProduct/Modal/ModalSave'

import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueBarcode from 'vue-barcode'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    ProdukEceran,
    PriceDiscount,
    Duplikat,
    Riwayat,
    ModalSave,
    BProgress,
    InitialStock,
    StreamBarcodeReader,
    VueBarcode
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      isDelay: true,
      messages: Object,
      isLoading: false,
      formPayload: {
        product_type: 'retail',
        image: '',
        retail_convertion: 0,
        retail_parent_uuid: '',
        uom_uuid: '',
        alias_name: '',
        standard_price: 0,
        minimum_price: 0,
        initial_stocks: [
          {
            warehouse_uuid: '',
            note: '',
            stock: 0,
          },
        ],
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      },
      image_url: null,
      editId: this.$route.params.id ? this.$route.params.id : null,
      step: this.$store.state.formWizard.step,
      progressValue: 45,
      disabled: true,
      arr_warehouse: [],
      disabledWarehouse: true,
      showBarcodeScanner: false,
      barcode_number: null
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.id': function (value) {
      this.editId = value
    },
    '$store.state.formWizard.step': function (value) {
      this.step = value
      if (this.$route.params.id) {
        this.getDetail()
      }
      if (!this.$route.params.id) {
        this.getPayloadPrice()
        this.getPayloadProduct()
        // this.getPayloadInitialStock()
      }
      this.stepForm()
    },
    '$store.state.parentProduct.retail': {
      handler(value) {
        this.disabledStep1(value)
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail()
    }
  },
  methods: {
    ...mapActions('parentProduct', ['postData']),
    toggleBarcodeScanner() {
      this.showBarcodeScanner = !this.showBarcodeScanner
    },
    generateBarcode() {
      this.barcode_number = Math.floor(Math.random() * 90000000000) + 1
      // this.formPayload.barcode_number = this.barcode_number
    },
    onDecode(result) {
      this.barcode_number = result
    },
    onLoaded() {
      console.log('Barcode reader loaded')
    },
    disabledStep1(value) {
      const isUpdate = this.$route.params.id !== undefined;

      let form = {}
      if (value) {
        form = {
          retail_parent_uuid: value.retail_parent_uuid,
          uom_uuid: value.uom_uuid,
          alias_name: value.alias_name,
        }
        if (!isUpdate) {
          form.min_low_stock = value.min_low_stock || '';
        }
        this.disabled = Object.values(form).some(e => e === '')
      }
    },
    nextSubmit() {
      this.$bvModal.show('modal-save')
      // if (this.$route.params.id) {
      //   this.$bvModal.show('modal-save')
      // }
    },
    prevPage() {
      switch (this.step) {
        case 1:
          this.$router.go(-1)
          break
        case 2:
          this.$store.commit('formWizard/setStep', 1)
          break
        case 3:
          this.$store.commit('formWizard/setStep', 2)
          break
        default:
          break
      }
    },
    stepForm() {
      switch (this.step) {
        case 1:
          this.progressValue = 45
          break
        case 2:
          this.progressValue = 75
          break
        case 3:
          this.progressValue = 100
          break
        default:
          break
      }
    },
    async getDetail() {
      this.isDelay = true
      if (this.$route.params.id) {
        await this.$store.dispatch('parentProduct/getData', {
          params: '',
          uuid: this.$route.params.id,
        }).then(result => {

          const { data } = result.data;
          const {
            retail_convertion,
            parent_product,
            uom,
            alias_name,
            sku_code,
            specification,
            standard_price,
            minimum_price,
            buying_price,
            discounts,
            discount_start_date,
            discount_end_date,
            stock_in_warehouse,
            show_on_low_stock,
            min_low_stock,
            image_url,
            barcode_number
          } = data;

          this.formPayload = {
            product_type: 'retail',
            retail_convertion,
            retail_parent_uuid: parent_product?.uuid,
            uom_uuid: uom.uuid,
            alias_name,
            sku_code,
            specification,
            standard_price,
            minimum_price,
            buying_price,
            discounts,
            discount_start_date,
            discount_end_date,
            stock_in_warehouse,
            initial_stocks: stock_in_warehouse,
            show_on_low_stock,
            min_low_stock
          };
          this.barcode_number = barcode_number
          this.arr_warehouse = stock_in_warehouse.map(e => e.uuid);
          this.image_url = image_url;
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      }
    },
    getWarehouseUuid(value) {
      this.formPayload.warehouses_uuid = value
    },
    getPayloadPrice(value) {
      this.formPayload = value
    },
    getPayloadProduct(value) {
      this.formPayload = value
    },
    getPayloadInitialStock(value) {
      this.disabledWarehouse = value.every(e => !e.warehouse_uuid)
    },
    clearItem() {
      this.$store.commit('parentProduct/setEditId', null)
      this.$store.commit('parentProduct/setImageUrl', null)
      this.formPayload = {
        product_type: 'retail',
        image: '',
        retail_convertion: 0,
        retail_parent_uuid: '',
        uom_uuid: '',
        alias_name: '',
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      }
    },
    createItem() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin membuat produk eceran baru?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const form = this.preparePayload()
          this.isLoading = true
          this.postData({
            query: '',
            payload: form,
          })
            .then(() => {
              successNotification(this, 'Success', 'Produk berhasil dibuat')
              this.clearItem()
              this.isLoading = false
              this.$router.push({ name: 'master-product.index', query: { q: 'produk-eceran' } })
              this.$store.commit('formWizard/setStep', 1)
            })
            .catch(error => {
              if (error.response.data.meta.messages) {
                // eslint-disable-next-line no-restricted-syntax
                for (const key in error.response.data.meta.messages) {
                  if (Object.hasOwnProperty.call(error.response.data.meta.messages, key)) {
                    const element = error.response.data.meta.messages[key]
                    errorNotification(this, 'Oops!', element.join(', '))
                  }
                }
                this.messages = error.response.data.meta.messages
              }
              this.isLoading = false
            })
        }
      });
    },
    updateItem() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin mengubah data produk eceran ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // this.formPayload.product_type = 'retail'
          const form = this.preparePayload()
          this.isLoading = true
          form.append('_method', 'PATCH')

          this.$store.dispatch('parentProduct/postData', {
            query: `/${this.editId}`,
            payload: form,
          })
            .then(() => {
              this.$store.commit('formWizard/setStep', 1)
              successNotification(this, 'Success', 'Produk berhasil diubah!')
              this.clearItem()
              this.isLoading = false
              this.$router.push({ name: 'master-product.index', query: { q: 'produk-eceran' } })
            })
            .catch(error => {
              if (error.response.data.meta.messages) {
                this.messages = error.response.data.meta.messages
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false
            })
        }
      });
    },
    preparePayload() {
      const isUpdate = this.$route.params.id !== undefined;

      this.formPayload = {
        product_type: 'retail',
        image: this.$store.state.parentProduct.retail.image,
        specification: this.$store.state.parentProduct.retail.specification,
        sku_code: this.$store.state.parentProduct.retail.sku_code,
        retail_parent_uuid: this.$store.state.parentProduct.retail.retail_parent_uuid,
        uom_uuid: this.$store.state.parentProduct.retail.uom_uuid,
        retail_convertion: this.$store.state.parentProduct.retail.retail_convertion,
        alias_name: this.$store.state.parentProduct.retail.alias_name,
        standard_price: this.$store.state.parentProduct.discounts.standard_price,
        minimum_price: this.$store.state.parentProduct.discounts.minimum_price,
        discounts: this.$store.state.parentProduct.discounts.discounts,
        discount_start_date: this.$store.state.parentProduct.discounts.discount_start_date,
        discount_end_date: this.$store.state.parentProduct.discounts.discount_end_date,
        buying_price: this.$store.state.parentProduct.discounts.buying_price,
        initial_stocks: this.$store.state.parentProduct.initial_stocks,
        show_on_low_stock: isUpdate ? this.$store.state.parentProduct.retail.show_on_low_stock : 1,
        min_low_stock: this.$store.state.parentProduct.retail.min_low_stock,
        barcode_number: this.barcode_number
      }
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'discounts' && key !== 'initial_stocks') {
            form.append(key, this.formPayload[key])
          }
        }
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.formPayload.discounts.length; index++) {
        const element = this.formPayload.discounts[index]
        // eslint-disable-next-line no-restricted-syntax
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            // eslint-disable-next-line no-use-before-define
            const items = element[key]
            form.append(`discounts[${index}][${key}]`, items)
          }
        }
      }
      if (this.formPayload.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.formPayload.initial_stocks[0].length; index++) {
          const element = this.formPayload.initial_stocks[0][index]
          if (element.warehouse_uuid !== '' && element.warehouse_uuid !== false) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key]
                if (key !== 'stock' && key !== 'note') {
                  form.append(`initial_stocks[${index}][${key}]`, items)
                }
              }
            }
          }
        }
      }
      return form
    },
    directPage() {
      this.clearItem()
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

body {
  background-color: #fff !important;
}

.bg-dark {
  background: $dark !important;
}

.text-error {
  color: #F48989;
}

.custom__input {
  &.error-validation {
    background: #FEEFEF;
    border: 1px solid #F48989;

    .input-group-text {
      background-color: #FEEFEF !important;
      border: 1px solid #F48989 !important;
    }
  }
}

.error-validation {
  .input-group-text {
    background-color: #FEEFEF !important;
    border: 1px solid #FEEFEF !important;
  }
}

.v-select {
  &.error-validation {
    .vs__dropdown-toggle {
      background: #FEEFEF !important;
      border: 1px solid #F48989 !important;
    }
  }
}

.progress-bar-success {
  background-color: #EFF1F5;

  .progress-bar {
    background: linear-gradient(269.89deg, #6CDFD4 0%, #45B6AB 98.81%) !important;
    box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
    border-radius: 20px;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}
</style>
